import React from "react";
import { useLocations } from "hooks/app";
import { Flex, Select, SelectProps, Spin } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function SelectLocations(props: SelectProps) {
  const { t, i18n } = useTranslation();

  const { data, isFetchingNextPage, isLoading, fetchNextPage, hasNextPage } =
    useLocations();

  const selectOptions: SelectProps["options"] = useMemo(
    () =>
      data?.pages
        .map((page) => page.inventories)
        .flat()
        .map((inventory) => ({
          value: inventory.id,
          label: i18n.language === "ar" ? inventory.ar_name : inventory.name,
        })),
    [data]
  );

  const scrollingHandler: SelectProps["onPopupScroll"] = (e) => {
    const target = e.currentTarget;
    if (
      target.scrollHeight - target.clientHeight === target.scrollTop &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  return (
    <Select
      notFoundContent={
        isLoading ? (
          <Flex justify="center">
            <Spin spinning={true} />
          </Flex>
        ) : undefined
      }
      loading={isLoading || isFetchingNextPage}
      allowClear
      onPopupScroll={scrollingHandler}
      options={selectOptions}
      placeholder={t("forms.placeholders.select_locations")}
      mode="multiple"
      {...props}
    />
  );
}
