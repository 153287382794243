import { Category, TransformedData, TreeNode } from "types/budget";

export const transformData = (data: Category[]) => {
  return data?.map((item) => {
    const transformedItem: TransformedData = {
      value: item.id,
      label: item.name,
      title: item.name,
    };
    if (item.children && item.children.length > 0) {
      transformedItem.children = transformData(item.children);
    }
    return transformedItem;
  });
};

export const filterTreeNode = (inputValue: string, treeNode: TreeNode) => {
  return (
    treeNode.title.toLowerCase().includes(inputValue.toLowerCase()) ||
    treeNode.value == inputValue
  );
};

export const getBudgetIdFromUrl = () => {
  const urlParts = window.location.pathname.split("/");
  return urlParts[urlParts.length - 1];
};

export const calculateTotalAmount = (values) => {
  if (Array.isArray(values)) {
    return values.reduce((sum, val) => {
      if (typeof val === "number") {
        return sum + val;
      } else if (val && val.amount) {
        return sum + val.amount;
      } else if (val && val.value) {
        return sum + val.value;
      }
      return sum;
    }, 0);
  } else {
    return 0;
  }
};


export const addInitialData = (data, cycle, initialData, parentId = null) => {
  data.forEach((account) => {
    const values = account.budgeting_values?.values || [];
    initialData[account.id] = {
      ...account,
      ...Object.fromEntries(
        Array.from({ length: cycle.no }, (_, i) => [i + 1, values[i] || 0])
      ),
      budget_amount: calculateTotalAmount(values),
      children: account.children.map((child) => child.id),
      parentId,
    };

    if (account.children.length) {
      addInitialData(account.children, cycle, initialData, account.id);
    }
  });
};

export const updateParentNodes = (formData, cycle) => {
  Object.keys(formData).forEach((key) => {
    const record = formData[key];
    if (record.parentId) {
      let parentRecord = formData[record.parentId];
      if (parentRecord) {
        parentRecord.budget_amount = parentRecord.children.reduce(
          (sum, childId) => {
            const childRecord = formData[childId];
            return sum + (childRecord ? childRecord.budget_amount : 0);
          },
          0
        );

        for (let i = 1; i <= cycle.no; i++) {
          parentRecord[i] = parentRecord.children.reduce((sum, childId) => {
            const childRecord = formData[childId];
            return sum + (childRecord ? childRecord[i] || 0 : 0);
          }, 0);
        }

        formData[record.parentId] = parentRecord;
      }
    }
  });
};

export const resetDateToZero = (formData, cycle, recordId = null) => {
  const resetFormData = {};

  if (recordId) {
    Object.keys(formData).forEach((key) => {
      resetFormData[key] = {
        ...formData[key],
        budget_amount: Number(key.trim()) === recordId ? 0 : formData[key].budget_amount,
        ...Object.fromEntries(
          Array.from({ length: cycle.no }, (_, index) => [
            index + 1,
            Number(key.trim()) === recordId ? 0 : formData[key][index + 1],
          ])
        ),
      };
    });
  } else {
    Object.keys(formData).forEach((key) => {
      resetFormData[key] = {
        ...formData[key],
        budget_amount: 0,
        ...Object.fromEntries(
          Array.from({ length: cycle.no }, (_, index) => [index + 1, 0])
        ),
      };
    });
  }

  return resetFormData;
};

