import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Table,
  Alert,
  Form,
  Space,
  message,
  InputNumber,
  TableProps,
} from "antd";
import { BudgetContext } from "./BudgetDetails";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  postBudgetingValueBreakdown,
  getBudgetingValueBreakdowns,
} from "api/budgets";
import {
  useDynamicDimensionsColumns,
  useBreakDownModalState,
} from "hooks/budgets";
import { useMutation, useQuery } from "react-query";
import {
  BreakDownModalProps,
  BudgetBreakdownReqBody,
  BudgetContextType,
  DimensionValue,
} from "types/budget";

const BreakDownModal: React.FC<BreakDownModalProps> = ({
  data,
  dateRanges,
}) => {
  const { modalOpen, setModalOpen, selectedRow } =
    useContext<BudgetContextType>(BudgetContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const nameKey = i18next.language === "en" ? "name_en" : "name_ar";
  const name = data ? data[nameKey] : undefined;
  const breakDownValue = selectedRow?.[selectedRow?.field];
  const accountName = selectedRow?.name;
  const breakDownDateCode = selectedRow?.code;
  const valueIndex = selectedRow?.field - 1;
  const budgetingValueId = selectedRow?.budgeting_value_id;

  const { tableData, setTableData, errorMessage, setErrorMessage } =
    useBreakDownModalState(selectedRow);
  const [breakDownInputsValues, setBreakDownInputsValues] = useState<
    Record<number, number>
  >({});

  const [submittable, setSubmittable] = useState([]);

  const dynamicDimensionsColumns = useDynamicDimensionsColumns(
    data,
    (value: number, dimensionId: number, recordKey: number) => {
      setTableData((prevData) =>
        prevData.map((row) => {
          if (row.key === recordKey) {
            const updatedDimensions = row.dimensions_values.map(
              (dim: DimensionValue) => {
                if (dim.dimension_id === dimensionId) {
                  return { ...dim, id: value };
                }
                return dim;
              }
            );

            if (
              !updatedDimensions.some(
                (dim: DimensionValue) => dim.dimension_id === dimensionId
              )
            ) {
              updatedDimensions.push({ id: value, dimension_id: dimensionId });
            }

            return { ...row, dimensions_values: updatedDimensions };
          }
          return row;
        })
      );
    },
    tableData
  );

  const { data: breakdownData } = useQuery({
    queryKey: ["date_ranges", modalOpen, selectedRow, form],
    queryFn: () => getBudgetingValueBreakdowns(budgetingValueId, valueIndex),
  });

  const processBreakdownData = () => {
    if (Array.isArray(breakdownData)) {
      const savedAmounts = breakdownData.reduce((acc, value, index) => {
        acc[index] = value.value_broken_down;
        return acc;
      }, {});
      setBreakDownInputsValues(savedAmounts);

      const formattedData =
        breakdownData.length === 0
          ? [
              {
                key: 1,
                kind: `${accountName}-${breakDownDateCode}`,
                amount: 0,
                dimensions_values: [],
              },
            ]
          : breakdownData.map((breakdown, index) => ({
              key: breakdown.id || index + 1,
              kind: `${accountName}-${breakDownDateCode}`,
              amount: breakdown.value_broken_down,
              dimensions_values:
                breakdown.dimensions_values.map(({ id, dimension_id }) => ({
                  id,
                  dimension_id,
                })) || [],
            }));

      setTableData(formattedData);

      form.setFieldsValue(
        formattedData.reduce((acc, row) => {
          acc[row.key] = { value_broken_down: row.amount };
          return acc;
        }, {})
      );
    } else {
      const initialRow = {
        key: 1,
        kind: `${accountName}-${breakDownDateCode}`,
        amount: 0,
        dimensions_values: [],
      };

      setTableData([initialRow]);
      form.setFieldsValue({
        [initialRow.key]: { value_broken_down: initialRow.amount },
      });
    }
  };

  const createBreakDownValues = useMutation({
    mutationFn: (data: BudgetBreakdownReqBody) => {
      return postBudgetingValueBreakdown(data);
    },
    onSuccess(data, variables, context) {
      message.success(t("budgets.show.budget_details.saved"));
    },
  });

  useEffect(() => {
    if (!modalOpen || !selectedRow) return;

    form.resetFields();
    setBreakDownInputsValues({});
    setTableData([]);

    processBreakdownData();
  }, [modalOpen, selectedRow, form, breakdownData]);

  useEffect(() => {
    setErrorMessage("");
  }, [modalOpen]);

  const handleInputChange = (value: number, index: number) => {
    const newValues = { ...breakDownInputsValues, [index]: value };

    setBreakDownInputsValues(newValues);

    const total = Object.values(newValues).reduce(
      (sum, val) => sum + (val || 0),
      0
    );

    if (total > breakDownValue) {
      setErrorMessage(`${t("budgets.break_down_popup.error_message")}`);
      setSubmittable([...submittable, 2]);
    } else {
      setErrorMessage("");
      setSubmittable(submittable.filter((item) => item !== 2));
    }

    setTableData((prevData) =>
      prevData.map((row, i) => (i === index ? { ...row, amount: value } : row))
    );
  };

  const handleAddRow = () => {
    const newRow = {
      key: `${Date.now()}-${Math.random()}`,
      kind: `${accountName}-${breakDownDateCode}`,
      amount: 0,
      dimensions_values: [],
    };
    setTableData([...tableData, newRow]);
  };

  const handleRemoveRow = (index: number) => {
    const newResult = Object.keys(breakDownInputsValues).reduce((acc, key) => {
      const numKey = parseInt(key);
      if (numKey < index) {
        acc[numKey] = breakDownInputsValues[key];
      } else if (numKey > index) {
        acc[numKey - 1] = breakDownInputsValues[key];
      }
      return acc;
    }, {});
    setBreakDownInputsValues(newResult);
    setTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const areDimensionsEqual = (a: DimensionValue[], b: DimensionValue[]) => {
      if (a.length !== b.length) return false;
      const sortedA = [...a].sort((x, y) => x.dimension_id - y.dimension_id);
      const sortedB = [...b].sort((x, y) => x.dimension_id - y.dimension_id);

      return sortedA.every(
        (item, index) =>
          item.id === sortedB[index].id &&
          item.dimension_id === sortedB[index].dimension_id
      );
    };

    const hasDuplicateDimensions = tableData.some((obj, index) =>
      tableData
        .slice(index + 1)
        .some((otherObj) =>
          areDimensionsEqual(obj.dimensions_values, otherObj.dimensions_values)
        )
    );

    await form.validateFields();
    const budgetingValueBreakdown = tableData.map((row) => {
      return {
        dimensions_values:
          row.dimensions_values.map((el: DimensionValue) => {
            return el.id;
          }) || [],
        value_broken_down: row.amount,
      };
    });

    const requestBody = {
      budgeting_value_id: budgetingValueId,
      value_index: valueIndex,
      budgeting_value_breakdown: budgetingValueBreakdown,
    };

    if (hasDuplicateDimensions) {
      setErrorMessage(
        `${t("budgets.break_down_popup.submit.duplicate_validation")}.`
      );
    } else {
      setErrorMessage(`${""}`);
      createBreakDownValues.mutate(requestBody as BudgetBreakdownReqBody);
      setModalOpen(false);
    }
  };

  const columns: TableProps["columns"] = [
    ...dynamicDimensionsColumns,
    {
      title: t("templates.amount"),
      dataIndex: "amount",
      key: "amount",
      width: 100,
      render: (text, record, index) => {
        return (
          <Form.Item
            name={[record.key, "value_broken_down"]}
            initialValue={record.amount}
            rules={[
              {
                required: true,
                message: `${t(
                  "budgets.break_down_popup.submit.amount_validation"
                )}`,
              },
              {
                type: "number",
                min: 1,
                message: `${t(
                  "budgets.break_down_popup.submit.amount_validation"
                )}`,
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              value={record.amount}
              onChange={(value) => handleInputChange(value, index)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "add",
      key: "add",
      render: (text, _, index) => (
        <Space>
          {text}
          {index === tableData.length - 1 ? (
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={handleAddRow}
            />
          ) : (
            <Button
              type="link"
              icon={<MinusCircleOutlined />}
              onClick={() => handleRemoveRow(index)}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title={
        <div>
          <div>
            {" "}
            <strong>{name}</strong>
          </div>
          <div>
            {accountName}-{breakDownDateCode}
          </div>
          <div>{Array.isArray(dateRanges) && dateRanges[valueIndex]}</div>
        </div>
      }
      centered
      open={modalOpen}
      onCancel={() => {
        setModalOpen(false);
        setTableData([]);
      }}
      style={{ maxWidth: "1100px" }}
      width={"auto"}
      onOk={() => handleSubmit()}
      okText={t("budgets.break_down_popup.confirm")}
      cancelText={t("budgets.break_down_popup.cancel")}
      okButtonProps={{ disabled: Boolean(submittable.length) }}
    >
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <Form form={form} onFinish={handleSubmit}>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{ x: "max-content" }}
          summary={() => {
            const totalAmount = tableData.reduce(
              (sum, row) => sum + (row.amount || 0),
              0
            );
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={columns.length - 1} />
                <Table.Summary.Cell index={1}>
                  <strong>{totalAmount}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Form>
    </Modal>
  );
};

export default BreakDownModal;
