import React from "react";
import { useTranslation } from "react-i18next";
import RecurringTransactionsIcon from "assets/icons/Recurring-Transactions.svg";
import QOnBoardingIndex from "components/layout/QOnBoardingIndex";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const NoRecurringTransactions = () => {
  const { t } = useTranslation();

  return (
    <QOnBoardingIndex
      icon={<RecurringTransactionsIcon />}
      title={t("recurring_transactions.onboarding.title")}
      subtitle={t("recurring_transactions.onboarding.desc")}
      extra={
        <Button
          type="primary"
          href="recurring_transactions/new"
          style={{ height: "auto", whiteSpace: "pre-wrap" }}
        >
          <PlusOutlined /> {t("recurring_transactions.create.button")}
        </Button>
      }
    />
  );
};

export default NoRecurringTransactions;
