import React, { useEffect, useRef, useState } from 'react'
import { Button, Space, Table, TableProps, Flex, Typography, Modal, Tooltip} from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import QPageLayout from 'components/layout/QPageLayout';
import { useMutation, useQuery } from 'react-query';
import { getDimension, getDimensionValues } from 'api/dimensions';
import { DimensionType, DimensionValue, DimensionValueResponse } from 'types/dimensions';
import { DeleteOutlined, PlusOutlined, PoweroffOutlined, EditOutlined} from '@ant-design/icons';
import { useDimensionPermissions } from 'hooks/dimensions';
import { Permissions } from 'types/app';
import { HookAPI } from 'antd/es/modal/useModal';
import axios, { AxiosError } from 'axios';

const { Text } = Typography;

export const useBreadCrumbData = (dimensionRecord: DimensionType) => {
  const { t } = useTranslation();
  return [
      {
        title: t('activerecord.attributes.layout.settings'),
      },
      {
        title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
        title: <a href={`/tenant/dimensions/${dimensionRecord?.id ?? ''}`}>{dimensionRecord?.code ?? ''}</a>,
      },
      {
        title: t('activerecord.attributes.dimension_value.name'),
      },
  ]
}

const LOCALIZED_VALUES = new Map([['parent_value', 'activerecord.attributes.dimension_value.value_types.parent_value'], ['standard', 'activerecord.attributes.dimension_value.value_types.standard']])

const useColumns = ({ permissions, modal }: { permissions: Permissions, modal: HookAPI }): TableProps<DimensionValue>['columns'] => {
  const { t } = useTranslation();

  const procElements = useRef(new Map());
  const mutation = useMutation({
    mutationKey: ["updateActiveState"],
    mutationFn: async (record: DimensionValue) => {
      const { dimension_id, id } = record;
      if(record.activated) {
        await axios.patch(`/tenant/dimensions/${dimension_id}/values/${id}/deactivate.json`)
        record.activated = false;
      } else {
        await axios.patch(`/tenant/dimensions/${dimension_id}/values/${id}/activate.json`)
        record.activated = true;
      }     
    },
    onSettled(_data, _error, variables) {
      procElements.current.delete(variables.id);      
    },
  })
  
  const handleDelete = (dimensionValue: DimensionValue, e: React.MouseEvent) => {
    e.preventDefault();
    const { id, dimension_id } = dimensionValue;
    modal.confirm({
      content: (
        <Flex vertical gap={16} justify='center' align='center'>
          <DeleteOutlined style={{color: "#e13659", fontSize: 40}} />
          <Text>{t("activerecord.attributes.dimension_value.index.actions.confirm_delete")}</Text>
        </Flex>
      ),
      icon: null,
      okButtonProps: {danger: true},
      okText: t("forms.buttons.confirm"),
      onOk: async () => {
        try {
          await axios.delete(`/tenant/dimensions/${dimension_id}/values/${id}.json`)
          window.location.reload();
        } catch(e) {
          const error = (e as AxiosError);
          if(error.response.status === 422) {
            modal.info({
              content: (
                <Flex vertical gap={16} justify='center' align='center'>
                  <DeleteOutlined style={{color: "#e13659", fontSize: 40}} />
                  <Text>{t("activerecord.attributes.dimension_value.index.actions.delete_error")}</Text>
                </Flex>
              ),
              icon: null,
              okText: t("forms.buttons.ok")
            })
          }
        }
      }
    })
    
  }
  
  const handleActivate = (dimensionValue: DimensionValue, e: React.MouseEvent) => {
    e.preventDefault();
    const { id, dimension_id } = dimensionValue;
    modal.confirm({
      content: (
        <Flex vertical gap={16} justify='center' align='center'>
        <PoweroffOutlined style={{color: "#e13659", fontSize: 40}} />
        <div style={{ textAlign: 'center' }}>
          {
            dimensionValue.activated ? (
              <Text>{t("activerecord.attributes.dimension_value.status.pop_up_messages.confirm_deactivation")}</Text>
            ) : (
              <Text>{t("activerecord.attributes.dimension_value.status.pop_up_messages.confirm_activation")}</Text>
            )
          }
        </div>
        <div style={{ textAlign: 'center' }}>
          <Text>{t("activerecord.attributes.dimension_value.status.pop_up_messages.warning_message")}</Text>
        </div>
      </Flex>
      ),
      icon: null,
      okButtonProps: {danger: true},
      okText: t("forms.buttons.confirm"),
      onOk: () => {
        mutation.mutate(dimensionValue); 
        procElements.current.set(dimensionValue.id, true);
      }
    })
  }
  
  return ([
    {
      title: t('activerecord.attributes.dimension.index.code'),
      dataIndex: 'code',
      width: '20%'
    },
    {
      title: t('activerecord.attributes.dimension.index.name'),
      width: '20%',
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        i18next.language === 'en'? record.name_en : record.name_ar
      )
    },
    {
      title: t('activerecord.attributes.dimension_value.value_type'),
      width: '20%',
      dataIndex: 'value_type',
      render: (_, record) => {
        return t(LOCALIZED_VALUES.get(record.value_type) ?? '');
      }
    },
    {
      title: t('activerecord.attributes.dimension_value.status.title'),
      width: '20%',
      dataIndex: 'status',
      render: (_, record) => {
        return record.activated ? t('activerecord.attributes.dimension_value.status.active') : t('activerecord.attributes.dimension_value.status.inactive');
      }
    },
    {
      title: t('activerecord.attributes.dimension.index.actions.title'),
      width: '20%',
      key: 'actions',
      render: (_, record) => (
          <Space>
            {permissions?.write && (
              <Tooltip placement="top" title={record.activated ? t('activerecord.attributes.dimension_value.status.deactivate') : t('activerecord.attributes.dimension_value.status.activate')}>
                <Button 
                  icon={<PoweroffOutlined />} 
                  type="primary" 
                  disabled={mutation.isLoading && procElements.current.get(record.id)}
                  onClick={ handleActivate.bind(null, record)}
                  style={{ backgroundColor: record.activated ? 'darkred' : 'darkgreen' }}
                /> 
              </Tooltip>
            )}
            {permissions?.write && (
              <Button href={`/tenant/dimensions/${record.dimension_id}/values/${record.id}/edit`} icon={<EditOutlined />} />
            )}
            {permissions?.delete && (
              <Button danger onClick={handleDelete.bind(null, record)} icon={<DeleteOutlined />} />
            )}

          </Space>
      )
    },
  ])
}

const DimensionValueIndex = ({ id }) => {
  
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const fetchedValues = useRef(new Map());
  const [data, setData] = useState([]);
  const [activeParent, setActiveParent] = useState(0);
  const [modal, modalContext] = Modal.useModal();
  const { permissions } = useDimensionPermissions();

  const dimensionQuery = useQuery<DimensionType>({
    queryKey: ['dimension'],
    queryFn: getDimension.bind(null, { dimensionID: id })
  })

  const breadCrumbData = useBreadCrumbData(dimensionQuery.data);
  const columns = useColumns({ permissions, modal });

  const rootDimensionValues = useQuery<DimensionValueResponse>(['rootDimensionValues', page], getDimensionValues.bind(null, { dimensionID: id, page, parentID: null }));

  const dimensionValues = useQuery<DimensionValueResponse>(['dimensionValues', activeParent], getDimensionValues.bind(null, { dimensionID: id, page: 1, parentID: activeParent }), {
    enabled: Boolean(activeParent)
  });


  useEffect(() => {
    if(Boolean(rootDimensionValues.data)) {      
      const newData = rootDimensionValues.data.records.map(record => {
        fetchedValues.current.set(record.id, record.id);
        const row = {...record};
        if(record.value_type === 'parent_value') {
          row['children'] = [];
        }
        return row;
      })
      setData(newData);
    }
  }, [rootDimensionValues.data])

  useEffect(() => {
    if(Boolean(activeParent) && Boolean(dimensionValues.data?.records.length)) {
      const newDataBranch = dimensionValues.data.records.map(record => {
        fetchedValues.current.set(record.id, activeParent);
        const row = {...record};
        if(record.value_type === 'parent_value') {
          row['children'] = [];
        }
        return row;
      })

      newDataBranch.sort((a, b) => b.value_type.localeCompare(a.value_type))
     
      let parentID = activeParent;
      
      const path = [parentID];
      while(Boolean(parentID) && parentID !== fetchedValues.current.get(parentID)) {
        parentID = fetchedValues.current.get(parentID)
        path.push(parentID);
      }
      path.reverse();
      setData(state => {
        let tmpData = state;
        for(let i = 0; i < path.length; i++) {
          const id = path[i];
          const idx = tmpData.findIndex(record => record.id == id);
          if(i === path.length - 1) {
            tmpData[idx].children = newDataBranch;
          } else {
            tmpData = tmpData[idx].children;
          }
        }
        return [...state]
      })
      
    }
  }, [dimensionValues.data, activeParent])

  const handleExpandRow = (expanded, record) => {
    if(expanded) {
      setActiveParent(record.id);
    }
  }
  
  
  let actionButtons = (
    <Space>
      {permissions?.write && (
        <Button icon={<PlusOutlined />} type="primary" href={`/tenant/dimensions/${id}/values/new`}>{t('activerecord.attributes.dimension.view.actions.add_dimension_value')}</Button>
      )}
    </Space>
  )

  
  return (
    <QPageLayout title={t('activerecord.attributes.dimension_value.index.title', { code: dimensionQuery.data?.code ?? '' })} actionBtns={actionButtons} breadCrumbData={breadCrumbData} >
      <Table
        expandable={{onExpand: handleExpandRow}}
        rowKey="id"
        loading={rootDimensionValues.isLoading}
        columns={columns}
        dataSource={data ?? []}
        onChange={({current}) => { setPage(current); setActiveParent(0) }}
        pagination={{total: rootDimensionValues.data?.pagination.total_entries ?? 0}}
      />
      {modalContext}
    </QPageLayout>
  )
}

export default DimensionValueIndex
