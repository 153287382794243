import axios from "axios";
import { DimensionValue } from "types/dimensions";
import { QueryFunctionContext } from "react-query";


export const getDimension = async({ dimensionID }: {dimensionID: string}) => {
  return (await axios.get(`/tenant/dimensions/${dimensionID}.json`)).data
}

export const getDimensions = async (props: {page?: number, 'q[sorts]': string[]}) => {
  const queryParams = new URLSearchParams();
  for(const [key, value] of Object.entries(props)) {
    if(Array.isArray(value)) {
      value.forEach(val => queryParams.append(`${key}[]`, String(val)))
      continue;
    }
    queryParams.set(key, String(value));
  }
  return (await axios.get(`/tenant/dimensions.json?${queryParams.toString()}`)).data
}

export const getConfiguration = async({ dimensionID }: {dimensionID: string}) => {
  return (await axios.get(`/tenant/dimensions/${dimensionID}/configurations.json?q[enabled_eq]=true`)).data
}

export const updateConfigurations = async () => {
  return (await axios.get(`/tenant/dimensions.json`)).data
}

export const getParentDimensionValues = async ({ dimensionID }: { dimensionID: string }, { pageParam }: QueryFunctionContext<any, number>) => {  
  const { data } = await axios.get(`/tenant/dimensions/${dimensionID}/values.json?page=${pageParam ?? 1}&q[value_type_eq]=parent_value`);
  return data;
}

interface ValuesProps { 
  dimensionID: number; 
  cdType: string;
  page: number;
  type: string; 
  parentID?: number | null | undefined; 
  perPage?: number;
  valueID: string;
  disabledDimensionValues?: boolean;
  lineItem?: number | string;
}

export const getDimensionValues = async ({ dimensionID, page=1, parentID, cdType='', perPage=100, type='', valueID='', disabledDimensionValues, lineItem='' }: ValuesProps) => {  
  let parentQueryParam = '';
  if(typeof parentID !== 'undefined') {
    parentQueryParam = parentID == null? 'q[parent_id_null]=true' : `q[parent_id_eq]=${parentID}`;
  }
  
  let disabledQueryParam = '';
  if(typeof disabledDimensionValues !== 'undefined') {
    disabledQueryParam = `q[activated_eq]=${disabledDimensionValues}`;
  }

  const { data } = await axios.get(`/tenant/dimensions/${dimensionID}/values.json?per_page=${perPage}&page=${page}&q[available_for_cd]=${cdType}&q[value_type_eq]=${type}&q[id_eq]=${valueID}&q[available_for_line_item]=${lineItem}&${parentQueryParam}&${disabledQueryParam}`);
  return data;
}

export const getActivatedDimensionValues = ({...props}: ValuesProps) => getDimensionValues({...props, disabledDimensionValues: true})

export const updateDimension = async(id: number, {name_ar, name_en, description}: DimensionValue) => {  
  return (await axios.patch(`/tenant/dimensions/${id}.json`, {name_ar, name_en, description}));
}

export const getValuesByLineItem = async ({ lineItemID }: { lineItemID: string }) => {
  const { data } = await axios.get(`/tenant/dimension_value_assignables.json?q[by_external_line_item]=${lineItemID}`);
  return data;
}
