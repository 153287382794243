import React, { useContext, useEffect, useState } from 'react'

import { Select, Checkbox, Form, Typography, Row, Col } from 'antd';

import { formObservable, ModalContext } from 'contexts/dimensions';
import type { Dimension, DimensionValueResponse } from 'types/dimensions';
import { getActivatedDimensionValues, getDimensionValues } from 'api/dimensions';
import { useInfiniteQuery } from 'react-query';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const {Text} = Typography;

const SelectControl = ({ idx, hideItem }: { idx: number; hideItem: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const [isFetchValues, setIsFetchValues] = useState(false);
  const [dimension, setDimension] = useState<null | Dimension>(null);
  const [options, setOptions] = useState([]);
  const [trID, setTrID] = useState('');
  const [search, setSearch] = useState('');
  const [value, setValue] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [applied, setApplied] = useState(false);
  const [relationID, setRelationID] = useState(null);
  const [initialVal, setInitialVal] = useState(undefined);
  const [lineItemID, setLineItemID] = useState('');

  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const { appliedRef, dataRef, updateTmpApplyList } = useContext(ModalContext)

  const dimensionValuesQuery = useInfiniteQuery<DimensionValueResponse>(['dimensionValues', dimension, search, lineItemID], ({ pageParam=1 }) => {
    let extraQuery: Record<string, string | boolean> = { disabledDimensionValues: true };
    if(lineItemID) {
      extraQuery = { lineItem: lineItemID };
    }
    return getDimensionValues({ dimensionID: dimension?.id, cdType: 'Bill', type: 'standard', page: pageParam, valueID: search, ...extraQuery })
  }, {
    getNextPageParam: (lastPage) => lastPage.pagination.next_page,
    enabled: isFetchValues && Boolean(dimension?.id)
  })

  const handleApplied = (dimension) => {
    const globalApply = appliedRef.get(String(dimension?.id));
    if(globalApply?.[0]) {
      setApplied(true);
      setDisabled(true);
    } else {
      setApplied(false);
      setDisabled(false);
    }
  }
  
  
  useEffect(() => {
    if(dimensionValuesQuery.data) {
      const { pages } = dimensionValuesQuery.data;
      const allValues = pages.map(page => page.records).flat();
      setOptions(() => {
        return allValues.map(record => ({ label: `${record.code} - ${i18next.language === 'en'? record.name_en : record.name_ar}`, value: record.id }));
      })
    }
  }, [ dimensionValuesQuery.data?.pages ])
  
  
  useEffect(() => {
    const subscription = formObservable.subscribe(({dimensions, trID, lineItemID}) => {      
      if(dimensions.length > idx) {
        setDimension(dimensions[idx]);
        hideItem(false);
        setTrID(trID);
        setLineItemID(lineItemID ?? '');
      } else {
        hideItem(true);
      }
    })

    return () => {
      subscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if(trID && dimension?.id) {
      const trData = dataRef?.get(trID);
      
      if(trData) {
        const val = trData.find(dimensionRecord =>  dimensionRecord.dimension_id === (dimension.id as number))
        
        setInitialVal(val);
        handleApplied(dimension)
        if(val) {
          setIsFetchValues(true);
          setSearch(String(val.dimension_value_id))
          setValue(val.dimension_value_id)
          setRelationID(val.id);
        } else {
          setValue(undefined)
          setRelationID(null);
        }
      }
      
    }
  }, [trID, dimension])

  useEffect(() => {
    form.setFieldValue(`select-${idx}`, value);
    const isGlobal = appliedRef.get(String(dimension?.id))?.[0]
    if(isGlobal && Boolean(initialVal?.dimension_value_id)) {
      if(initialVal.dimension_value_id !== value) {
        setDisabled(false);
        setApplied(false);
      } else {
        setDisabled(true);
        setApplied(true);
      }
    }
  }, [value, initialVal])

  useEffect(() => {    
    if(dimension) {
      updateTmpApplyList(String(dimension?.id), applied, {value, id: relationID})
    }
  }, [applied, dimension])
  
  return (
    <Row gutter={16} align='middle'>
      <Col md={4} sm={24}>
        <Text>{i18next.language === 'en'? dimension?.name_en : dimension?.name_ar}</Text>
      </Col>
      <Col md={14} sm={18}>
        <Select
          options={options}
          onFocus={() => {setIsFetchValues(true); setSearch('')}}
          loading={dimensionValuesQuery.isLoading}
          onChange={(val) => setValue(val)}
          allowClear={true}
          value={value}
        />
      </Col>
      <Col md={6} sm={6}>
        <Checkbox style={{fontWeight: 'normal'}} onChange={e => setApplied(e.target.checked)} disabled={disabled} checked={applied}>
          {t("activerecord.attributes.dimension_value.line_item_apply")}
        </Checkbox>
      </Col>
    </Row>
  )
}

export default SelectControl