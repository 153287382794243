import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QPageLayout from 'components/layout/QPageLayout';
import { Button, Table, Space, TableProps, Skeleton, Row, Col, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import type { DimensionValue, DimensionValueResponse } from 'types/dimensions';
import i18next from 'i18next';
import { getDimension, getActivatedDimensionValues } from 'api/dimensions';
import { useDimensionPermissions } from 'hooks/dimensions';

const { Text } = Typography;

export const useBreadCrumbData = (code) => {
  const { t } = useTranslation();
  return [
      {
        title: t('activerecord.attributes.layout.settings'),
      },
      {
        title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
        title: code,
      }
  ]
}

interface DataType {
  id: string;
  code: string;
  name_en: string;
  name_ar: string;
  description: string;
}

const LOCALIZED_VALUES = new Map([['parent_value', 'activerecord.attributes.dimension_value.value_types.parent_value'], ['standard', 'activerecord.attributes.dimension_value.value_types.standard']])


const useColumns = (): TableProps<DimensionValue>['columns'] => {
  const { t } = useTranslation();
  return ([
    {
      title: t('activerecord.attributes.dimension.index.code'),
      dataIndex: 'code',
      width: '25%'
    },
    {
      title: t('activerecord.attributes.dimension.index.name'),
      width: '37.5%',
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        i18next.language === 'en'? record.name_en : record.name_ar
      )
    },
    {
      title: t('activerecord.attributes.dimension_value.value_type'),
      width: '37.5%',
      dataIndex: 'value_type',
      render: (_, record) => {
        return t(LOCALIZED_VALUES.get(record.value_type) ?? '');
      }
    },
  ])
} 


const DimensionShow = ({ id }: {id: number}) => {
  const { t } = useTranslation();
  const { permissions } = useDimensionPermissions();
  const columns = useColumns();
  const [page, setPage] = useState(1);
  
  const dimensionData = useQuery<DataType>(['dimension'], getDimension.bind(null, { dimensionID: id }));

  const breadCrumbData = useBreadCrumbData(dimensionData.data?.code ?? '');

  const dimensionValues = useQuery<DimensionValueResponse>(['dimensionValues', page], getActivatedDimensionValues.bind(null, { dimensionID: id, page }));

  const data = {...(dimensionData.data ?? {}), values: [...(dimensionValues.data?.records ?? [])]}

  useEffect(() => {
    if(dimensionData.isError || dimensionValues.isError || !Boolean(id)) {
      window.location.replace('/tenant/dimensions');
    }
  }, [ dimensionData.isError, dimensionValues.isError ])
  
  let actionButtons = (
    <Space>
      <Button type="primary" href={`/tenant/dimensions/${id}/values`}>{t("activerecord.attributes.dimension.view.actions.dimension_values")}</Button>
      {permissions?.write && (
        <Button icon={<PlusOutlined />} type="primary" href={`/tenant/dimensions/${id}/values/new`}>{t('activerecord.attributes.dimension.view.actions.add_dimension_value')}</Button>
      )}
    </Space>
  )
  
  return (
    <QPageLayout title={t('activerecord.attributes.dimension.view.dimension_details')} 
      actionBtns={actionButtons}
      breadCrumbData={breadCrumbData}>
        {dimensionData.isLoading || !Boolean(dimensionData.data)? 
          <Skeleton active /> 
          :
          (
            <>
              <Row gutter={[16, 16]}>
                <Col style={{fontWeight: 'bold'}} xs={12} md={6}>{t("activerecord.attributes.dimension.create_code.code")}</Col>
                <Col xs={12} md={6}>{data.code}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col style={{fontWeight: 'bold'}} xs={12} md={6}>{t("activerecord.attributes.dimension.create_code.name_ar")}</Col>
                <Col xs={12} md={6}><Text style={{fontFamily: 'Cairo'}}>{data.name_ar}</Text></Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col style={{fontWeight: 'bold'}} xs={12} md={6}>{t("activerecord.attributes.dimension.create_code.name_en")}</Col>
                <Col xs={12} md={6}>{data.name_en}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col style={{fontWeight: 'bold'}} xs={12} md={6}>{t("activerecord.attributes.dimension.create_code.description")}</Col>
                <Col xs={12} md={6}>{data.description}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col style={{fontWeight: 'bold'}} xs={24} md={6}>{t("activerecord.attributes.dimension.view.actions.dimension_values")}</Col>
                <Col xs={24} md={18}>
                  <Table rowKey="id" loading={dimensionValues.isLoading} columns={columns} dataSource={data.values} onChange={({current}) => setPage(current)} pagination={{total: dimensionValues.data?.pagination.total_entries ?? 0}} />
                </Col>
              </Row>
            </>
          ) 
        }
    </QPageLayout>
  )
}

export default DimensionShow