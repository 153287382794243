import React, { useEffect, useState, useRef } from 'react'
import { Modal, message } from 'antd';
import Form from './form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DimensionAssignableResponse } from 'types/dimensions';
import { useForm } from 'antd/es/form/Form';
import { useMutation, useQuery } from 'react-query';
import { getValuesByLineItem } from 'api/dimensions';

import { formObservable, ModalContext } from 'contexts/dimensions';

interface AssignableEntry {
  id: number;
  dimension_value_id: number;
  dimension_id: number;
  _destroy?: 1 | 0;
  apply_to_all?: boolean;
}

const DimesnionModal = ({ cdID, cdType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, modalContext] = Modal.useModal();
  const [messageApi, messageContext] = message.useMessage();
  const dataRef = useRef<Map<string, AssignableEntry[]>>(new Map());
  const appliedRef = useRef<Map<string, [boolean, {value: number, id: number}]>>(new Map())
  const tmpAppliedRef = useRef<Map<string, [boolean, {value: number, id: number}]>>(new Map())
  const [activeLineItem, setActiveLineItem] = useState("");
  const [activeDimensionList, setActiveDimensionList] = useState([]);
  const { t } = useTranslation();
  const [form] = useForm();

  const { data } = useQuery<DimensionAssignableResponse>({
    queryKey: ["lineItemData", activeLineItem],
    queryFn: getValuesByLineItem.bind(null, { lineItemID: activeLineItem }),
    enabled: Boolean(activeLineItem)
  })

  const mutation = useMutation({
    mutationKey: ['submitDimensionForm', activeLineItem],
    mutationFn: async(data: AssignableEntry[]) => {
      console.log(data);
      
      await axios.post('/tenant/dimension_value_assignables.json', {
        assignable_id: activeLineItem,
        cd_type: cdType,
        dimension_values: data
      })
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    },
    onSuccess: (_, variables) => {
      messageApi.open({
        type: 'success',
        content: t('forms.messages.update.success')
      }); 
      dataRef.current.set(activeLineItem, variables);
    }
  })
  
  const updateTmpApplyList = (dimensionID: string, applied: boolean, valueObj: {value: number, id: number}) => {
    tmpAppliedRef.current.set(dimensionID, [applied, {value: valueObj?.value, id: valueObj?.id}]);
  }

  const setUpRowDimension = (e: CustomEvent) => {
    const {id, action} = e.detail;
    const dimensionIcon = document.getElementById(`${id}`)?.querySelector('.line_item_dimension');

    if(!dimensionIcon) return;
    
    if(action === 'MOUNT') {
      dimensionIcon.addEventListener('click', showModal);
      if(!dataRef.current.has(id)) {
        dataRef.current.set(id, []); 
      }
    }
  }

  useEffect(() => {
    document.addEventListener('updateCdDimension', setUpRowDimension);
    
    return () => {
      document.removeEventListener('updateCdDimension', setUpRowDimension);
    }
  }, [])

  useEffect(() => {
    if(data) {
      for(const record of data.records) {
        const tr = document.querySelector(`tr[data-id="${record.external_line_item_id}"]`)
        if(Boolean(record.dimension_values.length)) {
          tr.querySelector('.line_item_dimension').classList.add('line-item-has-applied')
          tr?.querySelector('.ellipsis-container').classList.add('line-item-trigger-applied')
        }
        dataRef.current.set(activeLineItem, record.dimension_values)
      }
    }
  }, [ data, activeLineItem ])
  
  useEffect(() => {
    (async() => {
      if(isModalOpen) {
        const { data } = await axios.get(`/tenant/dimensions.json?q[available_for_cd_id]=${cdID}`);
        setActiveDimensionList(data.records);        
      }
    })()
  }, [ isModalOpen ])

  useEffect(() => {
    if(activeDimensionList.length) {
      formObservable.next({dimensions: activeDimensionList, trID: activeLineItem, lineItemID: activeLineItem});  
    }
  }, [ activeDimensionList ])
  
  const showModal = async (e: Event) => {
    const target = e.target as HTMLDivElement;
    const parentTR = target?.closest('tr')
    
    if(!parentTR) return;
    
    const id = parentTR.id;
    setIsModalOpen(true);
    setActiveLineItem(id);
  };

  const handleOk = () => {
    let isConfirmApply = false;
    let count = 0;
    for(const [isApplyAll] of tmpAppliedRef.current.values()) {
      if(isApplyAll) {
        isConfirmApply = true;
        ++count;
      }
    }
    if(isConfirmApply) {
      modal.confirm({
        title: t('activerecord.attributes.dimension_value.line_item_apply'),
        content: t('activerecord.attributes.dimension_value.line_item_confirm', {count}),
        onOk: () => {
          handleApply();
          window.location.reload();
        },
        okText: t('forms.buttons.confirm'),
        cancelText: t('forms.buttons.cancel')
      })
    } else {
      handleApply();
    }
  };

  const handleApply = () => {
    if(Boolean(activeDimensionList.length) && activeLineItem) {      
      
      const refObj = dataRef.current.get(activeLineItem)
      if(Boolean(refObj)) {
        const tr = document.getElementById(activeLineItem);
        const dimensionLi = tr?.querySelector('.line_item_dimension')
        const tmp: AssignableEntry[] = [];
        const formValues: any[] = Object.values(form.getFieldsValue());
        for(let i = 0; i < activeDimensionList.length; i++) {
          const dimension = activeDimensionList[i];
          const dimensionRef = refObj.find(item => item.dimension_id === dimension.id);
          tmp.push({id: dimensionRef?.id, dimension_id: dimension.id, dimension_value_id: formValues[i]})
        }
        
        if(Boolean(tmp.filter(entry => Boolean(entry.dimension_value_id)).length)) {
          dimensionLi.classList.add('line-item-has-applied');
          tr?.querySelector('.ellipsis-container').classList.add('line-item-trigger-applied')
        } else {
          dimensionLi.classList.remove('line-item-has-applied');
          if(!tr?.querySelector('.line_item_project_li').classList.contains('line-item-has-applied')) {
            tr?.querySelector('.ellipsis-container').classList.remove('line-item-trigger-applied')
          }
        }
        
        const data = tmp
          .filter(entry => (entry.id || entry.dimension_value_id))
          .map(entry => ({
            ...entry,
            apply_to_all: (tmpAppliedRef.current.get(String(entry.dimension_id))?.[0] && Boolean(entry.dimension_value_id)) ?? false,
            _destroy: Number(!Boolean(entry.dimension_value_id)) 
          }))
        
        mutation.mutate(data as AssignableEntry[]);
        
      }
    }
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    setActiveDimensionList([]);
    setActiveLineItem("");
    tmpAppliedRef.current = new Map();
    setIsModalOpen(false);
  };

  return (
    <Modal width={'60vw'} title={t("activerecord.attributes.dimension.name_plural")} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <ModalContext.Provider value={{dataRef: dataRef.current, appliedRef: appliedRef.current, updateTmpApplyList}} >
        <Form form={form} />
      </ModalContext.Provider>
      {modalContext}
      {messageContext}
    </Modal>
  );
}

export default DimesnionModal