import axios from "axios";
import { InvoiceData, SimpleBillData, CustomFieldApiResponse, InventoryApiResponse } from "types/app";
import { RecurringTransaction, RTPermissions, DocumentTypes } from "types/RT";
import { QueryFunction, QueryFunctionContext } from "react-query";

export async function fetchDocuments<T extends DocumentTypes>(
  documentType: T | null,
  searchParams: string = ""
): Promise<
  T extends "Invoices"
    ? { documents: InvoiceData[]; meta: { total_pages: number } }
    : { simple_bills: SimpleBillData[]; meta: { total_pages: number } }
> {
  let baseURL: string = "";

  switch (documentType) {
    case "Invoices":
      baseURL = "/tenant/invoices.json";
      break;
    case "Bills":
      baseURL = "/tenant/bills.json";
      break;
    case "SimpleBills":
      baseURL = "/tenant/simple_bills.json";
      break;
  }

  const searchParamsObj = new URLSearchParams(searchParams);

  if (!searchParamsObj.has("reset")) {
    searchParamsObj.set("reset", "true");
  }

  if (!searchParamsObj.get("q[status_eq]")) {
    searchParamsObj.append("q[status_in][]", "Approved");
    searchParamsObj.append("q[status_in][]", "Paid");
    searchParamsObj.append("q[status_in][]", "Partially Paid");
  }

  const url = `${baseURL}?${searchParamsObj}`;

  return (await axios.get(url)).data;
}

export async function getLocations({
  pageParam = 1,
}: QueryFunctionContext<"locations", number>) {
  const res = await axios.get<InventoryApiResponse>(
    `/tenant/inventories.json?page=${pageParam}`
  );
  return res.data;
}

export const getCustomFields: QueryFunction<CustomFieldApiResponse> = async ({
  queryKey,
}) => {
  return (
    await axios.get(
      `/tenant/cpn_custom_fields.json?q[custom_field_for_eq]=${queryKey[1]}`
    )
  ).data;
};

export async function getRTPermissions(): Promise<RTPermissions> {
  return (
    await axios.get(
      "/tenant/tenant_roles/permissions?module_names=Recurring%20Transactions,Invoices,Bills,Simple%20Bills"
    )
  ).data;
}

export const createRecurringTransaction = async (data: RecurringTransaction) => {
  const response = await axios.post("/tenant/recurring_transactions", data);
  return response.data;
};

export const getNextReference = async () => {
  const response = await axios.get("/tenant/recurring_transactions/next_reference");
  return response.data;
};
