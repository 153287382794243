import { Flex, Space, Typography } from "antd";
import React from "react";
import { IconProps } from "types/budget";

const BudgetCard = ({ icon, text }: IconProps) => {
  const { Text } = Typography;

  return (
    <Flex
      style={{
        backgroundColor: "#f4f4f4",
        border: "1px solid #e2e2e2",
        height: "175px",
        width: "175px",
      }}
      justify="center"
      align="center"
    >
      <Space direction="vertical" align="center">
        {icon}
        <Text style={{ marginTop: "25px", fontSize: "16px", display: "block" }}>
          {text}
        </Text>
      </Space>
    </Flex>
  );
};

export default BudgetCard;
